import { Box, Stack, Switch, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';
import React, { useState } from "react";


type RFFormTransmittersToggleProps = {
    checked : boolean,
    handleChange : (event : React.ChangeEvent<HTMLInputElement>) => void
}

export const RFFormTransmittersToggle = (props:RFFormTransmittersToggleProps) => {    

    const CustomSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 50,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#6A7485',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#white',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#6A7485',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

  
    return (
      <div>
        <Stack direction="row" sx={{marginLeft:'10px', marginTop:'3px'}} spacing={1} alignItems="center">        
        <CustomSwitch 
            checked={props.checked} 
            onChange={props.handleChange} 
            inputProps={{ 'aria-label': 'ant design' }} 
        />
        <Typography>{props.checked ? 'Bound transmitters' : 'Non bound transmitters' } </Typography>
        
      </Stack>
      </div>)
  }