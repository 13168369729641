import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import RFForm from './RFForm';
import { RFFormData, Transmitter } from '../pages/kepler-raster';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  
};

type RFModalProps = {
    open :  boolean,
    handleClose: (event : React.MouseEvent) => void,
    setRfColor:(color:string)=>void,
    onSubmit: (payload: any) => void,
    rfValues : RFFormData,
    setRfValues : (data : RFFormData)=>void,
    setTransmitters : (data : Transmitter[])=>void,
}

export default function RFModal(props:RFModalProps) {

 const {open, handleClose, setRfColor, onSubmit, rfValues, setRfValues, setTransmitters} = props


  return (
    <div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            RF Form
          </Typography>
          <RFForm 
            setRfColor={setRfColor}
            onSubmission={onSubmit}
            rfValues = {rfValues}
            setRfValues = {setRfValues}
            setTransmitters = {setTransmitters}

          />
        </Box>
      </Modal>
    </div>
  );
}