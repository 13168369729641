
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { UserRole } from '../AccessControl';

function CreateUserForm({ createUser, isLoading }: { createUser: (username: string, role: string) => void, isLoading: boolean}) {
  const [username, setUsername] = useState('');
  const [role, setRole] = useState(UserRole.EXPLORER);

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    createUser(username, role);
  };

  return (
    <Box
        sx={{
            // minHeight: '40vw',
            minWidth: '40vw'
        }}
        component="form"
        display="flex"
        flexDirection="column"
        gap={2} // For spacing
        onSubmit={handleSubmit}
    >
      <TextField
        required
        disabled={isLoading}
        type="email"
        fullWidth
        label="Username"
        variant="outlined"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      
      <FormControl disabled={isLoading} fullWidth variant="outlined">
        <InputLabel>Role</InputLabel>
        <Select
          required
          disabled={isLoading}
          value={role}
          onChange={(e) => setRole(e.target.value as UserRole)}
          label="Role"
        >
          <MenuItem value={UserRole.ADMIN}>Admin</MenuItem>
          <MenuItem value={UserRole.EXPLORER}>Explorer</MenuItem>
          <MenuItem value={UserRole.CLIENT}>Client</MenuItem>
        </Select>
      </FormControl>

      <Button fullWidth variant="contained" disabled={isLoading} color="primary" type="submit">
        Create User
      </Button>
    </Box>
  );
}

export default CreateUserForm;