import { Amplify } from "aws-amplify";
import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { getAuth } from "./components/utility/Map";
import { NotificationProvider } from "./context/Notifications";
import "./index.css";

import App from "./App";
import { MapProvider } from "./contexts/Map";
import ModalDemo from "./pages/ModalDemo";
import PinMap from './pages/PinMap';
import SaveData from './pages/SaveData';
import SignIn from "./pages/SignIn";

dotenv.config();

Amplify.configure({
  Auth: getAuth(),
});

ReactDOM.render(
  <React.StrictMode>
    <NotificationProvider>
      <MapProvider>
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path={"/map"} element={<App />} />
            <Route path={"/modal"} element={<ModalDemo />} />
            <Route path={"/saved-map"} element={<SaveData />} />
            <Route path={"/pin"} element={<PinMap />} />
          </Routes>
        </Router>
      </MapProvider>
    </NotificationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
