//@ts-nocheck
import React from "react";
import { AuthProvider } from "./contexts/Auth";
import RasterKepler from "./pages/kepler-raster";

const App = () => {
  return (
    <AuthProvider>
      <RasterKepler />
    </AuthProvider>
  );
};

export default App;
