
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { style } from '../SavedMaps';
import CloseIcon from '@mui/icons-material/Close';
import { buttonStyle } from '../../pages/kepler-raster';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { getUserToken } from '../utility/Map';
import { AlertType } from '../../context/Notifications';

function UpdateUserForm({ updateUser, isLoading, currentUsername, currentRole, currentEmail }: { updateUser: (username: string, role: string) => void, isLoading: boolean, currentUsername: string, currentRole: string, currentEmail: string}) {
  const [role, setRole] = useState(currentRole);
  const [ isOpen, setIsOpen ] = useState(false);
  

  const handleSubmit = async(e: { preventDefault: () => void; }) => {
    e.preventDefault();
    updateUser(currentUsername, role);
    setIsOpen(false);
  };


  return (
    <>
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(false)}
        >
            <Box sx={style}>
                <Box mb={2}>
                    <Typography variant='subtitle1' align="center">Update user</Typography>
                </Box>
                <IconButton onClick={() => setIsOpen(false)} style={{
                    backgroundColor: 'white',
                    position: 'fixed',
                    top: -50,
                    right: -50
                }}>
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={{
                        // minHeight: '40vw',
                        minWidth: '40vw'
                    }}
                    component="form"
                    display="flex"
                    flexDirection="column"
                    gap={2} // For spacing
                    onSubmit={handleSubmit}
                >
                <TextField
                    required
                    disabled={true}
                    type="email"
                    fullWidth
                    label="Username"
                    variant="outlined"
                    value={currentEmail}  
                />
                
                <FormControl disabled={isLoading} fullWidth variant="outlined">
                    <InputLabel>Role</InputLabel>
                    <Select
                    required
                    disabled={isLoading}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    label="Role"
                    >
                    <MenuItem value="ADMIN">Admin</MenuItem>
                    <MenuItem value="EXPLORER">Explorer</MenuItem>
                    <MenuItem value="CLIENT">Client</MenuItem>
                    </Select>
                </FormControl>

                <Button fullWidth variant="contained" disabled={isLoading} color="primary" type="submit">
                    Update User
                </Button>
                </Box>
            </Box>
        </Modal>
        <Tooltip title="Update User">
            <IconButton onClick={() => setIsOpen(true)} 
                size="small"
            sx={{
                backgroundColor: '#A0A7B4', 
                marginLeft: 1,
                color: 'white', 
                textTransform: 'capitalize !important',
                ":hover": {
                  bgcolor: "#818897"
                }}}
            >
                <DriveFileRenameOutlineIcon  style={{ fontSize: 12 }} />
            </IconButton>
        </Tooltip>
    </>
  );
}

export default UpdateUserForm;


