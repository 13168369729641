import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Tooltip, Typography, Chip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { truncateString } from '../../utility';
import { getUserNameAttributes } from '../utility/Map';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditUser from './EditUser';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { UserRole } from '../AccessControl';

type TableData= {
  email: string
  username: string,
  description: string,
  lastModified: Date,
  createdDate:Date
}

type SampleTableProps = {
  sampleData : TableData[]
  handleLoad : (username: string, role: string)=>void,
  handleClose: () => void,
  handleDelete: (id: string ) => void,
  searchText: string,
  isLoading: boolean,
  updateUser: (username: string, role: string ) => void
}

export const MONTHS = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

export enum UserStatus {
  CONFIRMED = "CONFIRMED",
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD"
}

const rowStatus = (status: UserStatus = UserStatus.CONFIRMED) => {

  if(status === UserStatus.CONFIRMED) {
    return (
      <Chip sx={{ width: "100%"}} size='small' color="success" label="Active" />
    )
  }

  if(status === UserStatus.FORCE_CHANGE_PASSWORD) {
    return (
      <Chip sx={{ width: "100%"}} size='small' color="error" label="Not Active" />
    )
  }

  return (
    <Chip sx={{ width: "100%"}} size='small' label={status} />
  )
}

const roleStatus = (status: UserRole = UserRole.EXPLORER) => {

  if(status === UserRole.ADMIN) {
    return (
      <Chip sx={{ width: "100%"}} size='small' color="success" label="Administator" />
    )
  }

  if(status === UserRole.CLIENT) {
    return (
      <Chip sx={{ width: "100%"}} size='small' color="error" label="External Client" />
    )
  }

  return (
    <Chip sx={{ width: "100%"}} color="info" size='small' label={"Explorer"} />
  )
}

export default function SampleTable(props:SampleTableProps) {
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [ user, setUser ] = React.useState<any>(null);
    
    const {sampleData, handleLoad, handleClose, handleDelete } = props

    const date_format = (timestamp: number | string) => {
        const dateObject = new Date(timestamp)
        
        let formatted_day: any = dateObject.getDate();
        if(formatted_day < 10) formatted_day = '0' + formatted_day
        const humanDateFormat = `${formatted_day} ${MONTHS[dateObject.getMonth()]} ${dateObject.getFullYear()}`
        return humanDateFormat;
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const visibleRows = React.useMemo(
        () =>
            sampleData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [ page, rowsPerPage, sampleData],
    );

    React.useEffect(()=>{
      setPage(0)
    },[sampleData]);

    React.useEffect(() => {
      const getUser = async() => {
        const user = await getUserNameAttributes();
        setUser(user);
      }

      getUser();
    }, [])

    
  
    if(sampleData?.length === 0 || sampleData === undefined) {
      return (
        <Box sx={{ minWidth: 650 }}>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          
            <Typography color={"grey"} mt={2} textAlign={"center"}>
              No Users Found...
            </Typography>
          </Box>
        </Box>
      )
    }

    const loadingStyles = props?.isLoading ? { pointerEvents: 'none', opacity: 0.5 }: {};
    
    const sortByUpdated = (a: TableData , b: TableData, descending: boolean = true) => {
      const dateA = new Date(a.lastModified);
      const dateB = new Date(b.lastModified);

      return descending ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
    }

    return (
    <Paper sx={{ width: '100%', mb: 2 }}>
    <TableContainer component={Paper}>
      <Table size='small' sx={{ minWidth: 1050, ...loadingStyles }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell sx={{fontWeight:'bold'}}>User ID</TableCell> */}
            <TableCell sx={{fontWeight:'bold'}}>Username</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign: 'center'}}>Created</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign: 'center'}}>Updated</TableCell>
            <TableCell sx={{fontWeight: 'bold', textAlign: 'center' }}>User Type</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign: 'center'}}>Status</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign: 'center'}}>Is Enabled</TableCell>
            <TableCell sx={{fontWeight:'bold', textAlign: 'center'}}>Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {visibleRows.sort((a, b) => sortByUpdated(a, b)).filter((row) => row?.email?.toLowerCase().includes(props?.searchText?.toLowerCase())).map((row:any) => (
            <TableRow
              selected={(row?.username === user?.sub)}
              key={row?.username}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, ':hover': { backgroundColor: '#d3d3d3', cursor: 'pointer' }, opacity: row?.enabled ? 1 : 0.5  }}
            >
              {/* <TableCell component="th" scope="row">
                <Tooltip title={row?.username}><Typography>{truncateString(row?.username, 10) || '---'}</Typography></Tooltip>
              </TableCell> */}
              <TableCell><Tooltip title={row?.email}><Typography>{truncateString(row?.email, 100)}</Typography></Tooltip></TableCell>
             
              <TableCell>{date_format(row?.createdDate)}</TableCell>
              <TableCell>{date_format(row?.lastModified)}</TableCell>
              <TableCell>
                {roleStatus(row?.role)}
              </TableCell>
              <TableCell>
                {/* {row?.userStatus} */}
                <Box sx={{ display: 'flex', justifyItems: 'center'}}>
                  {rowStatus(row?.userStatus)}
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                {row?.enabled ? <CheckIcon /> : <CloseIcon />}
              </TableCell>
              <TableCell >
                <Box >
                  {
                    row?.enabled && row?.userStatus !== UserStatus.FORCE_CHANGE_PASSWORD ? (
                      (
                        <Tooltip title="Disable user">
                          <IconButton onClick={()=> { handleDelete(row?.username); } } size="small" sx={{
                            backgroundColor: '#A0A7B4', 
                            marginLeft: 1,
                            color: 'white', 
                            textTransform: 'capitalize !important',
                            ":hover": {
                              bgcolor: "#818897"
                            }}}>
                              <DeleteIcon style={{ fontSize: 12 }} />
                          </IconButton>
                        </Tooltip>
                      )
                    ): (
                      <Tooltip title="Reactivate">
                        <IconButton onClick={()=> { handleLoad(row?.email, ''); } } size="small" sx={{
                          backgroundColor: '#A0A7B4', 
                          marginLeft: 1,
                          color: 'white', 
                          textTransform: 'capitalize !important',
                          ":hover": {
                            bgcolor: "#818897"
                          }}}>
                            <AutorenewIcon style={{ fontSize: 12 }} />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  <EditUser 
                    isLoading={props.isLoading}
                    updateUser={props.updateUser}
                    currentEmail={row?.email}
                    currentUsername={row?.username}
                    currentRole={row?.role ? row?.role :''} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={sampleData?.length ? sampleData.length : 0 }
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  );
}
