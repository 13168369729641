import { Auth } from 'aws-amplify';
// Utility for map related functions

export const getPointsFeature = (data: any) => {
    const coordinates = data.map((point: any) => [point.lon, point.lat])

    return coordinates.map((coordinate: any) => ({
        type: 'Feature',
        geometry: {
        type: 'Point',
            coordinates: coordinate
        },
        properties: {
            category: 'Point',
            market_name: "Additional Market Info Heading",
            information: "Additional Information"
        }
    }))
}

const getLineStringsFeature = (data: any) => {

    const lines = data.map((item: any) => {
        const geom = JSON.parse(item.geom);
        return {
            type: 'Feature',
            geometry: geom,
            properties: {
                category: 'Line',
                information: 'LineString Additional Information',
            },
        };
    });

    return lines
}
export const convertToGeoJSON = (pointData: any, linesData: any) => {
    const points = getPointsFeature(pointData);
    const lines = getLineStringsFeature(linesData)
    const geoJSON = {
        type: 'FeatureCollection',
        features: [...points,...lines]
    }

    return geoJSON
}

export const fetchData = async  (min_lon = '0', max_lon = '0', min_lat = '0', max_lat = '0') => {
    const URL = process.env?.REACT_APP_URL || 'http://localhost'
    const responsePoints = await fetch(`${URL}/geometries/sites?min_lon=${min_lon}&max_lon=${max_lon}&min_lat=${min_lat}&max_lat=${max_lat}`)
    const points = await responsePoints.json();

    const responseLines = await fetch(`${URL}/geometries/paths?min_lon=${min_lon}&max_lon=${max_lon}&min_lat=${min_lat}&max_lat=${max_lat}`)
    const lines = await responseLines.json();
    return {
        points,
        lines
    }
}

export const fetchMarketData = async (marketId: string) => {
    const URL = process.env?.REACT_APP_URL || 'http://localhost'
    const response = await fetch(`${URL}/geometries/markets?market_id=${marketId}`)
    const marketData = await response.json();
    return marketData;
}

export enum MapStyles {
    Streets = "mapbox://styles/mapbox/streets-v11",
    Outdoors = "mapbox://styles/mapbox/outdoors-v11",
    Light = "mapbox://styles/mapbox/light-v10",
    Dark = "mapbox://styles/mapbox/dark-v10",
    Satellite = "mapbox://styles/mapbox/satellite-v9",
    SatelliteStreets = "mapbox://styles/mapbox/satellite-streets-v11",
}

export const calculateCenter = (coordinates: number[][]) => {
    if (coordinates.length === 0) {
        // Return default center if no coordinates provided
        return { latitude: 0, longitude: 0 };
    }

    const totalCoordinates = coordinates.length;
    let sumLat = 0;
    let sumLng = 0;

    coordinates.forEach(coord => {
        sumLat += coord[1];
        sumLng += coord[0];
    });

    const centerLat = sumLat / totalCoordinates;
    const centerLng = sumLng / totalCoordinates;

    return { latitude: centerLat, longitude: centerLng };
}


export const generateCoordinates = (maxLong: number, minLong: number, maxLat: number, minLat: number) => {
    return [
        [minLong, maxLat], // top left
        [maxLong, maxLat], // top right
        [maxLong, minLat], // bottom right
        [minLong, minLat]  // bottom left
    ];
}


export const getAuth = () => {
    return {
        identityPoolId: process.env?.REACT_APP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env?.REACT_APP_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env?.REACT_APP_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env?.REACT_APP_USER_POOL_WEB_CLIENT_ID
    }
}

export const getUserToken = async() => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const userToken = currentUser?.signInUserSession?.accessToken?.jwtToken;
      return userToken;
    } catch (error) {
      return null
    }
}

export const getUserNameAttributes = async() => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        return currentUser?.attributes
    } catch (error) {
        return null
    }
}
export const convertBboxToCoordinates = (bbox: number[]) => {
    const maxLat = bbox[0];
    const maxLon = bbox[1];
    const minLat = bbox[2];
    const minLon = bbox[3];

    return [
        [minLon, maxLat], // top-left
        [maxLon, maxLat], // top-right
        [maxLon, minLat], // bottom-right
        [minLon, minLat]  // bottom-left
    ];
}

export const overlayRasher = async (map: any, image: string, coordinates: number[]) => {
    if(map.loaded()) {
      if(map.getSource('image-source')) {
        await map.removeLayer('raster-layer');
        await map.removeSource('image-source');
        await map.addSource('image-source', {
          type: 'image',
          url: image,
          coordinates: convertBboxToCoordinates(coordinates)
        });
      } else {
        await map.addSource('image-source', {
          type: 'image',
          url: image,
          coordinates: convertBboxToCoordinates(coordinates)
        });
      }

      if(map.getLayer('raster-layer')) {
          // update layer
        await map.removeLayer('raster-layer');
        await map.addLayer({
          id: 'raster-layer',
          type: 'raster',
          source: 'image-source',
          paint: {
              'raster-fade-duration': 0
          }
        });
      } else {
          await map.addLayer({
              id: 'raster-layer',
              type: 'raster',
              source: 'image-source',
              paint: {
                  'raster-fade-duration': 0
              }
          });
      }
      
    }
  }

export const enum MapTypes {
    Dark = "dark",
    Light = "light",
    Muted = "muted",
    Road = "road",
    Satellite = "satellite",
    Voyager = "voyager"
}
