import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';

type TableData= {
    id : string, 
    name:string,
    createdBy:string,
    createdDate:Date
}

type SampleTableProps = {
    sampleData : TableData[]
    handleLoad : (id:string)=>void
}

export default function SampleTable(props:SampleTableProps) {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const {sampleData, handleLoad} = props

    const displayDate = (dateVal:Date) =>{
        return dateVal.toLocaleDateString()
    }


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const visibleRows = React.useMemo(
        () =>
            sampleData?.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [ page, rowsPerPage, sampleData],
    );

    React.useEffect(()=>{
      setPage(0)
    },[sampleData])

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
    <TableContainer component={Paper}>
      <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell sx={{fontWeight:'bold'}}>ID</TableCell> */}
            <TableCell sx={{fontWeight:'bold'}}>Name</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Description</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Created By</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Created Date</TableCell>
            <TableCell sx={{fontWeight:'bold'}}>Last Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.map((row:any) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.name || '---'}
              </TableCell>
              <TableCell>{row?.description}</TableCell>
              <TableCell>{row?.username}</TableCell>
              <TableCell>{displayDate(row.created_at)}</TableCell>
              <TableCell>{displayDate(row.created_updated)}</TableCell>
              <TableCell>

              <Button onClick={()=>handleLoad(row.client_data_id)} size="small" sx={{
                backgroundColor: '#6A7485', 
                color: 'white', 
                textTransform: 'capitalize !important',
                ":hover": {
                    bgcolor: "#818897"
                }}}>
                  Load Map Data
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={sampleData?.length ? sampleData.length : 0 }
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>
  );
}
