import React from "react"
import { Box } from "@mui/material"

const bwi = <Box>
    Bandwidth in MHz.
    <ul>
        <li>1MHz has a noise floor of -114dBm</li>
        <li>10MHz has a noise floor of -104dBm</li>
        <li>20MHz has a noise floor of -101dBm</li>
    </ul>
</Box>

const engine = <Box>
                    There are two available options to choose for your engine to process your output.
                    <ul>
                        <li>CPU - When you make use of a CPU engine, in the background of our service we hand over your calculation to a computer processor. This provides a great way to calculate your results using a tried and tested solution.               </li>
                        <li>GPU [beta] - A GPU engine makes use of graphics processing in the background. This new technology is fantastic at calculating complex queries. GPU is typically much faster than a CPU. Please note that GPU processing is currently in beta, if you have any feedback regarding this option then please send them to support@cloudrf.com.               </li>
                    </ul>
                </Box>

const ant = <Box>
                Antenna pattern code:
                <ul>
                    <li>0 - Allows custom options (see: hbw; vbw; fbr)</li>
                    <li>1 - Vertical dipole (omni-directional)</li>
                </ul>

            </Box>
const ked = <Box>
                Include knife edge diffraction for enhancing models with NLOS coverage (ITM includes this already):
                <ul>
                    <li>0 - Knife-edge diffraction disabled. In such cases the model applies a conservative line of sight.</li>
                    <li>1 - Knife-edge diffraction enabled.</li>
                </ul>
                </Box>

const elevation = <Box>
                    Elevation:
                    <ul>
                        <li>1 - Digital Surface Model (DSM)</li>
                        <li>2 - Digital Terrain Model (DTM)</li>
                    </ul>
                    </Box>



    
export const tooltips = {
    site : 'Unique name for your site',
    network : "Logical grouping for your site(s). Necessary for mesh and best server operations later on. Keep this to cities / regions",
    lat : "Latitude of transmitter in WGS84 decimal degrees",
    lon : "Longitude of transmitter in WGS84 decimal degrees",
    alt : "Altitude of transmitter above ground level in metric or imperial. Distance unit set in output object",
    frq : "Center frequency in megahertz.",
    txw : "Transmitter power in watts before the antenna.",
    bwi : bwi,
    res : "Resolution in meters for produced output. A high resolution (eg. 2m) is much slower than a medium resolution (eg. 20m) for area calculations.",
    col : "Colour schema code OR filename string of your chosen output colour schema.",
    rad : "Radius in kilometers for output. This is not a guarantee a signal will reach, only that is where you want to model out to. Set with care!",
    engine : engine,
    txg : "Transmitter antenna gain in dBi. A half-wave dipole has a gain of 2.15dBi. A mobile phone is 0 to 2dBi, depending on the frequency.",
    txl : "Feeder loss in dB. A long feeder has more loss. Loss varies by material and wavelength eg. LMR-400",
    ant : ant,
    azi : "Antenna azimuth in degrees North. You can use more than one azimuth at a time to simulate the same antenna pointing in different directions. To do this please use a comma-separated list, for example 0,120,240 would simulate 3 antennas spaced 120 degrees apart from true North. You can enter up to a maximum of 20 azimuth values.",
    tlt :"Antenna tilt in degrees below the horizon (inverted) where horizon is 0.",
    hbw : "Custom antenna horizontal beamwidth in degrees. For use only with ant value of 0.",
    vbw : "Custom antenna vertical beamwidth in degrees. For use only with ant value of 0.",
    fbr : "Front-to-back ratio measured in dB. A highly directional antenna will have a high FBR.",
    pol : "Antenna polarization",
    rla : "Latitude of receiver in WGS84 decimal degrees.",
    rlo	: "Longitude of receiver in WGS84 decimal degrees.",
    rxh	: "Altitude of receiver above ground level in metric or imperial. Distance unit set in output object.",
    rxg	: "Receiver antenna gain in dBi.",
    rxs	: "Receiver sensitivity/threshold in measured units. Measured units set in output object.",
    pm : "Propagation model",
    pe : "Propagation model subtype (environment)",
    cli	:"Radio climate for ITM model",
    ked	: ked,
    rel	: "Model reliability (fade margin) represented as a percentage (%). 50% adds 0dB loss, 99% adds 9.9dB loss.",    
    ter	: "Terrain short code for ITM model",
    clt	: "Clutter profile name. If not supplied, but cll > 0 then Minimal.clt will be used",
    elevation: elevation,
    landcover : "Global 10m landcover",
    buildings : "Global OSM buildings layer to enhance DTM",
    obstacles : "Custom obstacles / clutter",

}