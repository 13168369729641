import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { Button, IconButton, TextField, Tooltip, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { AlertType, useNotification } from '../../context/Notifications';
import { getUserToken } from '../utility/Map';
import MapRows from './MapRows';
import { buttonStyle } from '../../pages/kepler-raster';

export const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflow: "auto"
};



type TableData= {
  id : string, 
  name:string,
  createdBy:string,
  createdDate:Date
}

type SampleModalProps = {
    open :  boolean,
    handleClose: (event : React.MouseEvent) => void,
    sampleData:TableData[],
    handleLoad : (id:string)=>void
    handleSearch: (event:React.ChangeEvent<HTMLInputElement>)=>void,
    handleDelete: (id: string) => void,
    activeMapId: string | null,
    clearMap: () => void
}

const URL = process.env?.REACT_APP_URL || 'http://localhost'

export default function SampleModal(props:SampleModalProps) {
    const { handleLoad, handleSearch, handleDelete, activeMapId } = props
    const [ savedMaps, setSavedMaps ] = React.useState([]); 
    const { showAlert } = useNotification();
    const [ isOpen, setIsOpen ] = React.useState(true);
    const [ isLoading, setIsLoading ] = React.useState(false); 
    const [ searchText, setSearchText ] = React.useState('');
    const [allUsers, setAllUsers ] = React.useState([]);
    const [ isUserLoading, setUserIsLoading ] = React.useState(false);
    

    const getUsers = async () => {
        setIsLoading(true)
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/users`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
                }
            });
            const data = await response.json();
            setAllUsers(data?.users?.users)
        } catch(error)  {
            showAlert(AlertType.Error, "Error loading users")
        } finally {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        getUsers();
    }, []);

    React.useEffect(() => {
        const getSavedMaps = async () => {
            setIsLoading(true)
            try {
                const token = await getUserToken();
                let response = await fetch(`${URL}/client_data`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
                    }
                });

                const data = await response.json();
                setIsOpen(true);
                setSavedMaps(data);
            } catch (error) {
                setIsOpen(false);
                showAlert(AlertType.Error, "Error fetching maps")
            } finally {
                setIsLoading(false);
            }
            
        }

        if(isOpen) {
            getSavedMaps();
        }
       
    }, [isOpen]);

    
    const handleClose = () => {
        setIsOpen(false)
    }
    
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton onClick={() => setIsOpen(false)} style={{
                        backgroundColor: 'white',
                        position: 'fixed',
                        top: -50,
                        right: -50
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', mb: 3 }}>
                        <TextField size='small' placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target?.value)}></TextField>

                        <Tooltip title="Add Map Data">
                            <Button onClick={() => {
                                props.clearMap();
                                //@TODO: Fix this hacky work around to triggering add button
                                const add: any = document.querySelector('.add-data-button')
                                add?.click();
                                handleClose();
                            }} size="small" style={{ backgroundColor: '#A0A7B4', color: 'white' }} ><AddIcon /></Button>
                        </Tooltip>
                       
                    </Box>
                {
                    isLoading && <Box sx={{ display: 'flex', justifyContent: 'center'}}><CircularProgress /> </Box>
                }
                <MapRows 
                    activeMapId={activeMapId}
                    sampleData={savedMaps} 
                    handleLoad={handleLoad}
                    handleClose={handleClose}    
                    handleDelete={handleDelete} 
                    allUsers={allUsers}    
                    searchText={searchText}  
                    isUsersLoading={isUserLoading}     
                />
                </Box>
            </Modal>
            {/* <div style={{position: 'absolute', bottom: 255, right: 20 }}> */}
                <Tooltip title="View All Saved Maps">
                    <IconButton onClick={() => setIsOpen(true)} style={buttonStyle}>
                        <CloudDoneIcon style={{ fontSize: '16px'}} />
                    </IconButton>
                </Tooltip>

            {/* </div> */}
        </>
    );
}