import CellTowerIcon from '@mui/icons-material/CellTower';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import PinPopover from '../components/PinPopover';
import InputField from '../components/PinPopover/InputField';
import SelectBox from '../components/PinPopover/SelectBox';
import { getUserToken } from '../components/utility/Map';
import { AlertType, useNotification } from '../context/Notifications';
import { useMap } from '../contexts/Map';

const URL = process.env?.REACT_APP_URL || 'http://localhost'

interface IPin {
  id: number | string,
  isMoving: boolean, 
  updateMarkerLocation: (lat: number, long: number, id: string | number) => void, 
  coordinates: { lat: number, lng: number  }
  map: mapboxgl.Map,
  allCoordinates: { latitude: number, longitude: number, altitude: number, frequency: number, azi: number }[],
  removeMarker: (id: number | string ) => void,
  updateAltitude: (id: string | number, alititude: string | number) => void,
  updateFrequency: (id: string | number, alititude: string | number) => void,
  resolution: any,
  setResolution: any,
  radius: any, setRadius: any,
  tilt: any ,setTilt: any,
  azi: number | string,
  updateAzi: (id: string | number, azi: string | number) => void,
  progationModel: any, setPropogationModel: any
}

// @TODO: Move to a constants file
const PROPOGATION_MODELS = [
  { label: "Irregular Terrain Model (ITM) (Longley-Rice)", value: 1 },
  { label: "Line of Sight (LOS)", value: 2 },
  { label: "Okumura-Hata", value: 3 },
  { label: "ITU-R P.529 (ECC33)", value: 4 },
  { label: "SUI Microwave", value: 5 },
  { label: "COST231-Hata", value: 6 },
  { label: "ITU-R P.525 (Free space path loss)", value: 7 },
  { label: "Reserved", value: 8 },
  { label: "Ericsson 9999", value: 9 },
  { label: "Reserved", value: 10 },
  { label: "Egli VHF/UHF", value: 11 },
]

export const DEFAULT_FREQUENCY = 868;
export const DEFAULT_ALTITUDE = 1; 
export const DEFAULT_AZI = 0;

const FormData = (coordinates: { azi: number, latitude: number, longitude: number, altitude: number, frequency: number}[], propogation_model: number = 1 ) => {
  const data = {
      site: "Site",
      network: "DEMO",
      transmitters: coordinates.map((item) => {
        return {
          lat: item?.latitude,
          lon: item?.longitude,
          alt: item?.altitude,
          frq: item?.frequency,
          txw: 1,
          bwi: 1.2,
          ant: 0,
          antenna: {
            txg: 2.15,
            txl: 0,
            ant: 39,
            azi: item.azi,
            tlt: 0,
            hbw: 1,
            vbw: 1,
            fbr: 2.15,
            pol: "v"
          }
        }
      }),
      receiver: {
        alt: 1,
        rxg: 1,
        rxs: -102
      },
      model: {
        "pm": 11,
        "pe": 2,
        "ked": 0,
        "rel": 50,
        "ter": 3
      },
      environment: {
        clm: 0,
        cll: 0,
        clt: "Minimal.clt"
      },
      output: {
        units: "m",
        col: "RAINBOW.dBm",
        out: 2,
        nf: -120,
        res: 20,
        rad: 10
      }
  }
  
  return data;
}

const Pin = (props: IPin) => {
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const [ alititude, setAltitude ] = React.useState(DEFAULT_ALTITUDE);
  const [ frequency, setFrequency ] = React.useState(DEFAULT_FREQUENCY);
  const [ azi, setAzi ] = React.useState(DEFAULT_AZI)
  const [ internalLong, setInternalLong ] = React.useState(props.coordinates?.lng);
  const [ internalLat, setInternalLat ] = React.useState(props.coordinates?.lat);
  const { showAlert } = useNotification();
  const { setRFMapData, setTransmitterCoordinates } = useMap();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = anchorEl === null ? false: true;
  const id = open ? 'simple-popover' : undefined;

  const handleGenerate = async () => {
    setIsLoading(true);

    const bodyData = FormData(props.allCoordinates, props.progationModel);
    
    setTransmitterCoordinates(props.allCoordinates);

    try {
      const token = await getUserToken();
      //@TODO: Move this to a generic utility function
      let response = await fetch(`${URL}/geometries/multi_site_radio_propagation`, {
        method: 'POST',
        body: JSON.stringify(bodyData),
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
  
      const data = await response.json();

      // 
      setRFMapData(data); 
      overlayRasher(props.map, data?.image_url, data?.bounds);
    } catch (error) {
      console.log(error);
      showAlert(AlertType.Error, "Error Generating Signal View")
    } finally {
      setIsLoading(false);
    }
   
  }

  const convertBboxToCoordinates = (bbox: number[]) => {
    const maxLat = bbox[0];
    const maxLon = bbox[1];
    const minLat = bbox[2];
    const minLon = bbox[3];

    return [
        [minLon, maxLat], // top-left
        [maxLon, maxLat], // top-right
        [maxLon, minLat], // bottom-right
        [minLon, minLat]  // bottom-left
    ];
}
  const overlayRasher = async (map: any, image: string, coordinates: number[]) => {
    setAnchorEl(null);

    if(map.loaded()) {
      if(map.getSource('image-source')) {
        await map.removeLayer('raster-layer');
        await map.removeSource('image-source');
        await map.addSource('image-source', {
          type: 'image',
          url: image,
          coordinates: convertBboxToCoordinates(coordinates)
        });
      } else {
        await map.addSource('image-source', {
          type: 'image',
          url: image,
          coordinates: convertBboxToCoordinates(coordinates)
        });
      }

      if(map.getLayer('raster-layer')) {
          // update layer
        await map.removeLayer('raster-layer');
        await map.addLayer({
          id: 'raster-layer',
          type: 'raster',
          source: 'image-source',
          paint: {
              'raster-fade-duration': 0
          }
        });
      } else {
          await map.addLayer({
              id: 'raster-layer',
              type: 'raster',
              source: 'image-source',
              paint: {
                  'raster-fade-duration': 0
              }
          });
      }
      
      map.flyTo({
        center: [props?.coordinates?.lng, props?.coordinates?.lat], // [longitude, latitude] of the location to fly to lat=38.916&lon=1.448
      });
    }
  }

  React.useEffect(() => {
    setInternalLat(props?.coordinates?.lat);
    setInternalLong(props?.coordinates?.lng);

  }, [props?.coordinates]);

  const handleRemove = () => {
    setAnchorEl(null);
    //@TODO: index can be 0 and this could be falsy - do better unique generation
    if(props.id || props.id === 0) {
      props.removeMarker(props.id)
    }
    
  }

  return (
    <Box onClick={(e: any) => handleClick(e)}>
      <>
        <IconButton aria-describedby={id} style={{ backgroundColor: '#3a414c', color: '#A0A7B4' ,borderRadius: 100, cursor: 'grab' }}>
          <FmdGoodIcon sx={{ fontSize: `20px`}} />
        </IconButton>
        <PinPopover
          id={id}
          isMoving={props?.isMoving}
          anchorElement={anchorEl}
          open={open}
          handleClose={handleClose}
          handleRemove={handleRemove}
        >
          <Box display="flex">
            <InputField 
              label='Longitude'
              max={1000}
              min={0}
              unit={`°`}
              value={internalLong}
              onChange={(e) => setInternalLong(e as any)}
            />
            <InputField 
              label='Latitude'
              max={1000}
              min={0}
              unit={`°`}
              value={internalLat}
              onChange={(e) => setInternalLat(e as any)}
            />
            <Tooltip title="Navigate to coordinates">
              <Button onClick={(e) => { handleClose(e); props.updateMarkerLocation(internalLat, internalLong, props?.id)}} color="primary" variant="contained">
                <GpsFixedIcon />
              </Button>
            </Tooltip>
          </Box>
          <Box display="flex" mt={2}>
            <InputField 
              label='Frequency'
              max={1000}
              min={2}
              unit={'MHz'}
              value={frequency}
              onChange={(e) => {
                setFrequency(e as any);
                props.updateFrequency(props.id, e as any)
              }}
            />
            <InputField 
              label='Altitude'
              max={120000}
              min={0.1}
              unit={'m'}
              value={alititude}
              onChange={(e) => {
                setAltitude(e as any);
                props.updateAltitude(props.id, e as any);
              }}
            />
          </Box>
          <Box mt={2}>
            <InputField 
              label='Resolution'
              max={300}
              min={1}
              unit={'m'}
              value={props.resolution}
              onChange={(e) => props.setResolution(e as any)}
            />
            <InputField 
              label='Radius'
              max={400}
              min={0.03}
              unit={'km'}
              value={props.radius}
              onChange={(e) => props.setRadius(e as any)}
            />
          </Box>
          <Box mt={2}>
            <InputField 
              label='Tilt'
              max={90}
              min={-90}
              unit={'°'}
              value={props.tilt}
              onChange={(e) => props.setTilt(e as any)}
            />
            <InputField 
              label='Azimuth'
              max={0}
              min={360}
              unit={'°'}
              value={azi}
              onChange={(e) => {
                setAzi(e as any)
                props.updateAzi(props.id, e as any)
              }}
            />
          </Box>
          <Box mt={2}>
            <SelectBox 
              value={props.progationModel}
              onChange={(e) => props.setPropogationModel(e as any)}
              options={PROPOGATION_MODELS}
            />
            <Tooltip title="View signal strength">
              <Button onClick={handleGenerate} disabled={isLoading ? true: false } color="primary" variant="contained">{ isLoading? <CircularProgress sx={{ color: 'white' }} size={25} /> : <CellTowerIcon /> }</Button>
            </Tooltip>
          </Box>
        </PinPopover>
      </>
    </Box>
   
  );
}

export default Pin;