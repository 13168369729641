import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { AlertType, useNotification } from '../context/Notifications';
import { getUserToken } from './utility/Map';
import { FormControlLabel, FormGroup, CircularProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const URL = process.env?.REACT_APP_URL || 'http://localhost'

function getStyles(name: string, user: readonly string[], theme: Theme) {
  return {
    fontWeight:
      user.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultiUserSelect = ({ user, setUsers }: { user: any, setUsers: any }) => {
  const theme = useTheme();
  const [allUsers, setAllUsers ] = React.useState([]);
  const [ initialUsers, setInitialUsers ] = React.useState([])
  const [ isLoading, setIsLoading ] = React.useState(false);
  const { showAlert } = useNotification();
  const [ selectAll, setSelectAll ] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<typeof user>) => {
    const {
      target: { value },
    } = event;
    setUsers(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const getUsers = async () => {
    setIsLoading(true)
    try {
        const token = await getUserToken();
        const response = await fetch(`${URL}/users`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
            }
        });

        const data = await response.json();
        setAllUsers(data?.users?.users);
        setUsers(data?.users?.users.filter((item: any) => user.includes(item?.username)));
        setInitialUsers(data?.users?.users.filter((item: any) => user.includes(item?.username)))
    } catch(error)  {
        showAlert(AlertType.Error, "Error loading users")
    } finally {
        setIsLoading(false)
    }
  }
  React.useEffect(() => {
    getUsers();
  }, []);
  
 
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center'}}>
      { isLoading && <CircularProgress sx={{ alignSelf: 'center'}} />}
      <FormControl sx={{ mt: 2, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label">Access</InputLabel>
        <Select
          disabled={isLoading}
          // labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={user || []}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value?.username} label={value?.email} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          { isLoading && (<MenuItem>Loading Users...</MenuItem>)}
          {allUsers?.map((name: any) => (
            <MenuItem
              key={name?.username as any}
              value={name}
              style={getStyles(name?.username, allUsers, theme)}
            >
              {name?.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormGroup>
        <FormControlLabel 
          control={<Checkbox disabled={isLoading} checked={selectAll} onChange={(e) => {
            if(e.target.checked) {
              setUsers(allUsers);
              setSelectAll(e.target.checked)
            } else {
              setUsers(initialUsers);
              setSelectAll(e.target.checked)
            }
            
          }} />} label={"Select All"}        />
      </FormGroup>
    </div>
  );
}

export default MultiUserSelect;