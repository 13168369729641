import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

type IOption = { value: number | string, label: string}

interface ISelectBox {
    value: number | string,
    onChange: (e: any) => void,
    options: IOption[]
}

const SelectBox = ({
    value, 
    onChange,
    options = []
}: ISelectBox) => {
    return (
        <FormControl >
            <Select
                sx={{ mr: 2, maxWidth: '20vw', color: '#A0A7B4' }} 
                size='small'
                id="simple-select"
                value={value}
                onChange={(e) => {
                    onChange(e.target.value as any)
                }}
            >
                {
                    options.map((item) => (
                        <MenuItem value={item?.value}>{item?.label}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default React.memo(SelectBox);