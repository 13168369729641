import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { AlertType, useNotification } from '../../context/Notifications';
import { getUserToken } from '../utility/Map';
import { Tooltip } from '@mui/material';

const AccessList = ({ access_list, allUsers, isLoading }: { access_list: any, allUsers: any, isLoading: boolean} ) => {
   
    if(isLoading) {
        return null;
    }

    const usersWithAccess = allUsers?.filter((item: any) => access_list.includes(item?.username) );

    return (
            <AvatarGroup 
                sx={{
                    '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 },
                }}
                max={5}>
                {
                    usersWithAccess?.map((user: any) => (
                        <Tooltip title={user?.email}>
                            <Avatar sx={{ width: 24, height: 24 }} alt={user?.email} />
                        </Tooltip>
                        
                    ))
                }
            </AvatarGroup>
       
    );
}


export default AccessList