import React from 'react';
import { getUserNameAttributes } from './utility/Map';

export enum UserRole {
    ADMIN = "ADMIN",
    CLIENT = "CLIENT",
    EXPLORER = "EXPLORER",
  }

// Conditionally render a component based on logged in user and allowed list
const RoleGuard = ({ onlyShowTo = [], children }: { onlyShowTo: UserRole[], children: React.ReactNode }) => {
    const [ show, setShow ] = React.useState(false);
    
    const showUser = async() => {
        const attributes  = await getUserNameAttributes();
        if(onlyShowTo.includes(attributes["custom:role"] || '')) {
            setShow(true);
        }
    }
    React.useEffect(() => {
        showUser();
    }, []);

    if(!show) {
        return null;
    }

    return <>{children}</>;
}

export default RoleGuard;