import { useForm, Controller,useFieldArray, SubmitHandler } from "react-hook-form"
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Alert, Button, IconButton } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PodcastsOutlinedIcon from '@mui/icons-material/PodcastsOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TuneIcon from '@mui/icons-material/Tune';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { tooltips } from "./RFFormToolTips";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { RFFormData, Transmitter } from "../pages/kepler-raster";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {parse, ParseResult} from 'papaparse';
import { AlertType, useNotification } from '../context/Notifications';
import { RFFormTransmittersToggle } from "./RFFormTransmittersToggle";


type RFFormProps = {
    setRfColor:(color:string)=>void,
    onSubmission: (payload: any)=>void,
    rfValues : RFFormData,
    setRfValues : (data: RFFormData)=>void,
    setTransmitters : (data: Transmitter[])=>void,
}

const emptyTransmitter = {
          alt:2,
          frq:868,
          txw:0.1,
          rfPowerUnit:'W',
          bwi:1,
          antennaMode:'template',
          ant:0,
          pol:'h',
          txg:2.15,
          azi:1,
          tlt:10,
          hbw:120,
          vbw:120,
          fbr:1.95,
          txl:1
} as Transmitter

const RFForm = (props:RFFormProps) => {


    const {setRfColor, onSubmission, rfValues, setRfValues, setTransmitters} = props
    const [loading, setLoading] = React.useState(false)
    const [transmittersExpanded, setTransmittersExpanded] = React.useState(false);
    const [transmittersloading, setTransmittersLoading] = React.useState(false)
    const [showTransmitters, setShowTransmitters] = React.useState(true)
    const fileReader = new FileReader();
    const { showAlert } = useNotification();


    const { register, control,formState: { errors }, handleSubmit, setValue, watch, reset } = useForm({
        defaultValues: rfValues,
      })

      const { fields, append, remove } = useFieldArray({ name: 'transmitters', control });

    const addMoreTransmitters = () => {
        append(emptyTransmitter);
    }

    const removeTransmitter = (index: number) => {
        remove(index);
    }

    React.useEffect(() => {
        if (fields.length === 0) {
            remove()
            append(emptyTransmitter);
        }
    }, []);

    React.useEffect(() => {
        reset(rfValues)
    }, [reset, rfValues]);


      const colorVal = watch('col')
      React.useEffect(()=>{
        setRfColor(colorVal)
      },[colorVal])

      const onSubmit = (data:any) => {
        setLoading(true)
        setRfValues(data)
        const transmitters : any = []
        if(showTransmitters) {
            data.transmitters.forEach(function (transmitter:any) {
                transmitters.push({
                    "lat": parseFloat(transmitter.lat),
                    "lon": parseFloat(transmitter.lon),
                    "alt": parseFloat(transmitter.alt),
                    "frq": parseFloat(transmitter.frq),
                    "txw": parseFloat(transmitter.txw),
                    "bwi": parseFloat(transmitter.bwi),
                    "antenna": {
                        "txg": parseFloat(transmitter.txg),
                        "txl": parseFloat(transmitter.txl),
                        "ant": transmitter.antennaMode === 'custom' ? 0 : 1,
                        "azi": parseFloat(transmitter.azi),
                        "tlt": parseFloat(transmitter.tlt),
                        "hbw": parseFloat(transmitter.hbw),
                        "vbw": parseFloat(transmitter.vbw),
                        "fbr": parseFloat(transmitter.fbr),
                        "pol": transmitter.pol,
                    }
                })
            });
        }
        const formData = 
        {
            "site": data.site,
            "network": data.network,
            "transmitters": transmitters,
            "model": {
                "pm": data.pm,
                "pe": data.pe,
                "cli": 7,
                "ked": data.ked,
                "rel": data.rel,
                "ter": 3
            },
            "receiver": {
              "lat": data.rla,
              "lon": data.rlo,
              "alt": data.rxh,
              "rxg": data.rxg,
              "rxs": data.rxs
            },
            "environment": {
              "clt": data.clt,
              "elevation": data.elevation,
              "landcover": data.landcover,
              "buildings": data.buildings,
              "obstacles":data.obstacles,
            },
            "output": {
              "units": "metric",
              "col": data.col,
              "out": data.out,
              "ber": 2,
              "mod": 7,
              "nf": -100,
              "res": data.res,
              "rad": data.rad,
            }
          };
          console.log(formData)
          onSubmission(formData)
      }

    const CustomTypography = styled(Typography)`
        margin-top: 10px;
        padding-left: 2px;
    `;

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          fontSize: 16,
        },
      }));

    const onFileChange = (event:any) => {
        setTransmittersLoading(true)
        if (event.target.files[0]) {
            fileReader.onload = function (event) {
                if(event?.target?.result){
                    const csvOutput = event.target.result;
                    if(typeof(csvOutput) === 'string'){
                        parse(csvOutput, {
                                header:true, 
                                skipEmptyLines:true, 
                                complete: (result: any) => {
                                    setTransmitters(result.data)
                                    showAlert(AlertType.Success , "Transmitters uploaded successfully!")
                                    setTransmittersExpanded(true)
                                    setTransmittersLoading(false)
                                }
                            }
                        ); 
                    }           
                }
            };
            fileReader.readAsText(event.target.files[0]);
        }
 
    };

    const handleAccordionChange = () =>{
        if(transmittersExpanded){
            setTransmittersExpanded(false)
        }else{
            setTransmittersExpanded(true)
        }
    }

    const toggleTransmitters = () => {
        setShowTransmitters(!showTransmitters)
    }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Box maxHeight="500px" overflow="auto">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{background:"#818897"}}
          
        >
            <LocationOnOutlinedIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Site</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Name</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name="site"
                    control={control}
                    rules={{ required: true }}
                    render={({ field}) => <TextField error={!!errors.site}  size="small" id="site" variant="outlined" {...field} />}                    
                />
                <LightTooltip title={tooltips.site} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
               </Box>
                {errors.site && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Site name required</Alert>}
                </Box>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Network</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name="network"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <TextField error={!!errors.network} size="small" id="network"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.network} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
                </Box>
                {errors.network && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Network required</Alert>}
                </Box>
            </Box>     
        </Box>

        </AccordionDetails>
      </Accordion>

     {/* Transmitters section */}
     {showTransmitters && 
     <Accordion expanded={transmittersExpanded} onChange={handleAccordionChange}>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel2a-content"
    id="panel2a-header"
    sx={{background:"#818897"}}
    >
        <PodcastsOutlinedIcon/>
    <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Transmitter(s)</Typography>
    </AccordionSummary>
    <AccordionDetails>
    <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
        { fields.map((item, i) => (
        <Box sx={{border:'1px solid #D3D3D3', marginBottom:'20px'}}>
        <Box  sx={{ display:'flex', background:'#D3D3D3', flexDirection:'row', justifyContent:"space-between"}}>
        <Typography sx={{padding:"10px"}} fontWeight="bold">Transmitter {i +1 }</Typography> 
        {i > 0 &&
            <Tooltip title="Remove Transmitters">
                <IconButton onClick={()=>removeTransmitter(i)}>
                    <RemoveCircleOutlineIcon /> <Typography>Remove</Typography>
                </IconButton>
            </Tooltip>
        }
        </Box>
        <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Latitude</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.lat`}
                    control={control}
                    rules={{ required: true, min:-89, max:89 }}
                    render={({ field }) => <TextField error={!!errors?.transmitters?.[i]?.lat} type="number" size="small" id="lat"  variant="outlined" {...field} />}
                /><CustomTypography>D.D</CustomTypography>
                 <LightTooltip title={tooltips.lat} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
                </Box>
                {errors.transmitters?.[i]?.lat && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Latitude need to be between -89 and 89</Alert>}
                </Box>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Longitude</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.lon`}
                    control={control}
                    rules={{ required: true, min:-180, max:180 }}
                    render={({ field }) => <TextField type="number"  error={!!errors?.transmitters?.[i]?.lon} size="small" id="lon"  variant="outlined" {...field} />}
                /><CustomTypography>D.D</CustomTypography>
                <LightTooltip title={tooltips.lon} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
                </Box>
                {errors?.transmitters?.[i]?.lon && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Longitude need to be between -180 and 180</Alert>}
                </Box>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Height AGL</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                    <Controller
                        name={`transmitters.${i}.alt`}
                        control={control}
                        rules={{ required: true, min:0.1, max:120000 }}
                        render={({ field }) => <TextField type="number"  size="small" error={!!errors?.transmitters?.[i]?.alt} id="alt"  variant="outlined" {...field} />}
                    /><CustomTypography>m</CustomTypography>
                    <LightTooltip title={tooltips.alt} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
                    </Box>
                    <Box>
                        {errors?.transmitters?.[i]?.alt && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Altitude need to be between 0.1 and 120000</Alert>}

                    </Box>
                </Box>
                                  
            </Box>
            
                            
        <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <CustomTypography sx={{width:'130px'}}>Frequency</CustomTypography>
            <Box sx={{ display:'flex', flexDirection:'column'}}>
            <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.frq`}
                    control={control} 
                    rules={{ required: true, min:2, max:100000 }}                   
                    render={({ field }) => <TextField error={!!errors?.transmitters?.[i]?.frq} type="number" size="small" id="frq"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.frq} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
                </Box>
                {errors?.transmitters?.[i]?.frq && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Frequency need to be between 2 and 100000</Alert>}
            </Box>
        </Box>
        
        <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <CustomTypography  sx={{width:'130px'}}>RF Power</CustomTypography>
            <Box sx={{ display:'flex', flexDirection:'column'}}>
            <Box sx={{ display:'flex', flexDirection:'row'}}>
            <Controller
                name={`transmitters.${i}.txw`}
                control={control}
                rules={{ required: true, min:0.001, max:2000000 }}                          
                render={({ field }) => <TextField error={!!errors?.transmitters?.[i]?.txw} type="number"  sx={{width:"140px"}}  size="small" id="txw"  variant="outlined" {...field} />}
            />

            <FormControl size="small" variant="outlined">
                <Controller
                name={`transmitters.${i}.rfPowerUnit`}
                control={control}
                // rules={{ required: 'rfPowerUnit Required' }}
                render={({ field: { onChange, value} }) => (
                    <Select 
                    value={value}
                    onChange={(event) => {
                        setValue(
                        `transmitters.${i}.rfPowerUnit`,
                        (event?.target as HTMLInputElement).value
                        );
                    }}
                    >
                        <MenuItem value="dBm">dBm</MenuItem>
                        <MenuItem value="W">W</MenuItem>
                    </Select>
                )} 
                />
            </FormControl>
            <LightTooltip title={tooltips.txw} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
            </Box>
            {errors?.transmitters?.[i]?.txw && <Alert sx={{padding:'0px'}} icon={false} severity="error" >RF Power need to be between 0.001 and 2000000</Alert>}

            </Box>
        </Box>
        <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <CustomTypography  sx={{width:'130px'}}>Bandwidth</CustomTypography>
            <Box sx={{ display:'flex', flexDirection:'column'}}>
            <Box sx={{ display:'flex', flexDirection:'row'}}>
            <Controller
                name={`transmitters.${i}.bwi`}
                control={control}
                rules={{ required: true, min:0.001, max:200 }}
                render={({ field }) => <TextField error={!!errors?.transmitters?.[i]?.bwi} type="number"  sx={{width:"180px"}}  size="small" id="bwi"  variant="outlined" {...field} />}
            /> <CustomTypography>MHz</CustomTypography>
            <LightTooltip title={tooltips.bwi} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
            </Box>
            {errors?.transmitters?.[i]?.bwi && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Bandwidth need to be between 0.001 and 200</Alert>}

            </Box>
        </Box>

        <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Antenna</Typography>

        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Origin</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name={`transmitters.${i}.antennaMode`}
                    control={control}
                    // rules={{ required: 'antennaMode Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            `transmitters.${i}.antennaMode`,
                            (event?.target as HTMLInputElement).value
                            );
                        }}
                        >
                            <MenuItem value="template">My patterns</MenuItem>
                            <MenuItem value="custom">Custom pattern</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>  
            </Box>
            {(`transmitters.${i}.antennaMode` ==="template" ) &&
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Pattern</CustomTypography>
                
                <FormControl size="small" variant="outlined">
                    <Controller
                    name={`transmitters.${i}.ant`}
                    control={control}
                    // rules={{ required: 'ant Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            `transmitters.${i}.ant`,
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={39}>OEM Half-wave dipole</MenuItem>                            
                        </Select>
                    )} 
                    />
                </FormControl> 
                <LightTooltip title={tooltips.ant} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>            
               
            </Box>
            }
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Polarisation</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name={`transmitters.${i}.pol`}
                    control={control}
                    // rules={{ required: 'pol Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            `transmitters.${i}.pol`,
                            (event?.target as HTMLInputElement).value
                            );
                        }}
                        >
                            <MenuItem value="v">Vertical</MenuItem>
                            <MenuItem value="h">Horizontal</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.pol} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
            </Box>

            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Gain</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.txg`}
                    control={control}
                    rules={{ required: true, min:-10, max:60 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.txg} type="number" size="small" id="txg"  variant="outlined" {...field} />}
                /><CustomTypography>dBi</CustomTypography> 
                <LightTooltip title={tooltips.txg} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                 </Box>
                    {errors?.transmitters?.[i]?.txg && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Gain need to be between -10 and 60</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Azimuth</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name={`transmitters.${i}.azi`}
                            control={control}
                            rules={{ required: true, min:0, max:360 }}
                            render={({ field }) => <TextField error={!!errors?.transmitters?.[i]?.azi} type="number"  size="small" id="azi"  variant="outlined" {...field} />}
                        />
                        <LightTooltip title={tooltips.azi} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                    </Box>
                    {errors?.transmitters?.[i]?.azi && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Azimuth need to be between 0 and 360</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Tilt</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.tlt`}
                    control={control}
                    rules={{ required: true, min:-90, max:90 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.tlt} type="number"  size="small" id="tlt"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.tlt} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                </Box>
                    {errors?.transmitters?.[i]?.tlt && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Tilt need to be between -90 and 90</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>H-BW</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.hbw`}
                    control={control}
                    rules={{ required: true, min:0, max:360 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.hbw} type="number"  size="small" id="hbw"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.hbw} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                </Box>
                    {errors?.transmitters?.[i]?.hbw && <Alert sx={{padding:'0px'}} icon={false} severity="error" >H-BW need to be between 0 and 360</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>V-BW</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.vbw`}
                    control={control}
                    rules={{ required: true, min:0, max:360 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.vbw} type="number"  size="small" id="vbw"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.vbw} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                </Box>
                    {errors?.transmitters?.[i]?.vbw && <Alert sx={{padding:'0px'}} icon={false} severity="error" >V-BW need to be between 0 and 360</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Front to back</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.fbr`}
                    control={control}
                    rules={{ required: true, min:0, max:60 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.fbr} type="number"  size="small" id="fbr"  variant="outlined" {...field} />}
                /><CustomTypography>dB</CustomTypography>
                <LightTooltip title={tooltips.fbr} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                </Box>
                    {errors?.transmitters?.[i]?.fbr && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Front-to-back need to be between 0 and 60</Alert>}

                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Loss</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name={`transmitters.${i}.txl`}
                    control={control}
                    rules={{ required: true, min:0, max:60 }}
                    render={({ field }) => <TextField  error={!!errors?.transmitters?.[i]?.txl} type="number"  size="small" id="txl"  variant="outlined" {...field} />}
                /><CustomTypography>dB</CustomTypography>
                <LightTooltip title={tooltips.txl} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                </Box>
                    {errors?.transmitters?.[i]?.txl && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Loss need to be between 0 and 60</Alert>}

                </Box>
               
            </Box>
        </Box>
      </Box>
        ))}
        <Box width="100%" display="flex" justifyContent="center">
            <Tooltip title="Add More Transmitters">
                <IconButton onClick={() => addMoreTransmitters()}>
                    <AddCircleOutlineIcon /> <Typography>Add more transmitter</Typography>
                </IconButton>
            </Tooltip>
        </Box>
        </Box>

    </AccordionDetails>
    </Accordion>
    }

      {/* Output section  */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{background:"#818897"}}
        >
            <ColorLensOutlinedIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Output</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Resolution</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name="res"
                            control={control}
                            rules={{ required: true, min:1, max:300 }}
                            render={({ field }) => <TextField error={!!errors.res} type="number"  size="small" id="res"  variant="outlined" {...field} />}
                        />
                        <LightTooltip title={tooltips.res} placement="right" arrow>
                            <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                        </LightTooltip>
                    </Box>
                    {errors.res && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Resolution need to be between 1 and 300</Alert>}

                </Box>
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Color Schema</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="col"
                    control={control}
                    // rules={{ required: 'col Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "col",
                            (event?.target as HTMLInputElement).value
                            );
                        }}
                        >
                            <MenuItem value="GREEN.dBm">GREEN</MenuItem>
                            <MenuItem value="LORA.dBm">LORA</MenuItem>
                            <MenuItem value="LTE.dBm">LTE</MenuItem>
                            <MenuItem value="PLASMA.dBm">PLASMA</MenuItem>
                            <MenuItem value="RAINBOW.dBm" selected>RAINBOW</MenuItem>
                            <MenuItem value="RGB.dBm">RGB</MenuItem>
                            <MenuItem value="RSRP.dBm">RSRP</MenuItem>
                            <MenuItem value="1">Cellular (5)</MenuItem>
                            <MenuItem value="2">Red</MenuItem>
                            <MenuItem value="3">Green</MenuItem>
                            <MenuItem value="4">Blue</MenuItem>
                            <MenuItem value="5">Microwave (7)</MenuItem>
                            <MenuItem value="9">Greyscale GIS</MenuItem>
                            <MenuItem value="10">Rainbow (24)</MenuItem>
                            <MenuItem value="14">TV broadcasting (4)</MenuItem>
                            <MenuItem value="15">Boundary - RED</MenuItem>
                            <MenuItem value="16">Boundary - GREEN</MenuItem>
                            <MenuItem value="17">Boundary - BLUE</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.col} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>
                
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Radius</CustomTypography>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name="rad"
                            control={control}
                            rules={{ required: true, min:0.03, max:400 }}
                            render={({ field }) => <TextField error={!!errors.rad} type="number" sx={{width:"170px"}}  size="small" id="rad"  variant="outlined" {...field} />}
                        /> <CustomTypography>Km</CustomTypography>
                        <LightTooltip title={tooltips.rad} placement="right" arrow>
                            <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                        </LightTooltip>
                    </Box>
                    {errors.rad && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Radius need to be between 0.03 and 400</Alert>}

                </Box>
            </Box>

            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Engine</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="engine"
                    control={control}
                    // rules={{ required: 'engine Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "engine",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={2}>CPU</MenuItem>
                            <MenuItem value={1}>GPU</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.engine} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip>              
            </Box>
        </Box>

        </AccordionDetails>
      </Accordion>

      {/* Feeder section  */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{background:"#818897"}}
        >
            <ImportExportOutlinedIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Feeder</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Coaxial type</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="lineType"
                    control={control}
                    // rules={{ required: 'lineType Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "lineType",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={1}>Belden 8215 (RG-6A)</MenuItem>
                            <MenuItem value={2}>Belden 8237 (RG-8)</MenuItem>
                            <MenuItem value={3}>Belden 9913 (RG-8)</MenuItem>
                            <MenuItem value={4}>Belden 9258 (RG-8X)</MenuItem>
                            <MenuItem value={5}>Belden 8213 (RG-11)</MenuItem>
                            <MenuItem value={6}>Belden 8261 (RG-11A)</MenuItem>
                            <MenuItem value={7}>Belden 8240 (RG-58)</MenuItem>
                            <MenuItem value={8}>Belden 9201 (RG-58)</MenuItem>
                            <MenuItem value={9}>Belden 8219 (RG-58A)</MenuItem>
                            <MenuItem value={10}>Belden 8259 (RG-58C)</MenuItem>
                            <MenuItem value={11}>Belden 8212 (RG-59)</MenuItem>
                            <MenuItem value={12}>Belden 8263 (RG-59B)</MenuItem>
                            <MenuItem value={13}>Belden 9269 (RG-62A)</MenuItem>
                            <MenuItem value={14}>Belden 83241 (RG-141A)</MenuItem>
                            <MenuItem value={15}>Belden 8216 (RG-174)</MenuItem>
                            <MenuItem value={16}>Belden 8267 (RG-213)</MenuItem>
                            <MenuItem value={17}>Belden MRG2142 (RG-214)</MenuItem>
                            <MenuItem value={18}>Davis RF Bury-Flex</MenuItem>
                            <MenuItem value={19}>TMS LMR-100A</MenuItem>
                            <MenuItem value={20}>TMS LMR-200</MenuItem>
                            <MenuItem value={21}>TMS LMR-240</MenuItem>
                            <MenuItem value={22}>TMS LMR-400</MenuItem>
                            <MenuItem value={23}>TMS LMR-600</MenuItem>
                            <MenuItem value={24}>TMS LMR-900</MenuItem>
                            <MenuItem value={25}>Wireman CQ102 (RG-8)</MenuItem>
                            <MenuItem value={26}>Wireman CQ106 (RG-8)</MenuItem>
                            <MenuItem value={27}>Wireman CQ125 (RG-58)</MenuItem>
                            <MenuItem value={28}>Wireman CQ127 (RG-58C)</MenuItem>
                            <MenuItem value={29}>Wireman CQ110 (RG-213)</MenuItem>
                            <MenuItem value={30}>Tandy Cable RG-8X</MenuItem>
                            <MenuItem value={31}>Tandy Cable RG-58</MenuItem>
                            <MenuItem value={32}>Tandy Cable RG-59</MenuItem>
                            <MenuItem value={33}>Andrew Heliax LDF4-50A</MenuItem>
                            <MenuItem value={34}>Andrew Heliax LDF5-50A</MenuItem>
                            <MenuItem value={35}>Andrew Heliax LDF6-50A</MenuItem>
                            <MenuItem value={36}>Wireman 551 Ladder Line</MenuItem>
                            <MenuItem value={37}>Wireman 552 Ladder Line</MenuItem>
                            <MenuItem value={38}>Wireman 553 Ladder Line</MenuItem>
                            <MenuItem value={39}>Wireman 554 Ladder Line</MenuItem>
                            <MenuItem value={40}>Wireman 551 (wet)</MenuItem>
                            <MenuItem value={41}>Wireman 552 (wet)</MenuItem>
                            <MenuItem value={42}>Wireman 553 (wet)</MenuItem>
                            <MenuItem value={43}>Wireman 554 (wet)</MenuItem>
                            <MenuItem value={44}>Generic 300 ohm Tubular</MenuItem>
                            <MenuItem value={45}>Generic 450 ohm Window</MenuItem>
                            <MenuItem value={46}>Generic 600 ohm Open</MenuItem>
                            <MenuItem value={47}>Ideal (lossless) 50 ohm</MenuItem>
                            <MenuItem value={48}>Ideal (lossless) 75 ohm</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>  
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Coaxial length</CustomTypography>
                <Controller
                    name="lineLength"
                    control={control}
                    render={({ field }) => <TextField size="small" id="lineLength"  variant="outlined" {...field} />}
                />               
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Connectors</CustomTypography>
                <Controller
                    name="connectors"
                    control={control}
                    render={({ field }) => <TextField   size="small" id="connectors"  variant="outlined" {...field} />}
                /> 
            </Box>
           
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>ERP</CustomTypography>
                <CustomTypography>0.252W / +24dBm</CustomTypography>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>EIRP</CustomTypography>
                <CustomTypography>0.414W / +26.2dBm</CustomTypography>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Efficiency</CustomTypography>
                <Box sx={{bgcolor:'red'}}><Typography sx={{margin:'6px'}}>Poor(25.3%)</Typography></Box> 
               
            </Box>
        </Box>

        </AccordionDetails>
      </Accordion>

            


      {/* Mobile section  */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{background:"#818897"}}
        >
            <SmartphoneIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Mobile / Rx</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Latitude</CustomTypography></Box>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name="rla"
                    control={control}
                    rules={{ required: true, min:-89, max:89 }}
                    render={({ field }) => <TextField error={!!errors.rla} type="number" size="small" id="rla"  variant="outlined" {...field} />}
                />
                <LightTooltip title={tooltips.rla} placement="right" arrow>
                    <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                </LightTooltip> 
                 </Box>
                    {errors.rla && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Latitude need to be between -89 and 89</Alert>}

                </Box>
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <Box width="130px"><CustomTypography >Longitude</CustomTypography></Box>
            <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name="rlo"
                            control={control}
                            rules={{ required: true, min:-180, max:180 }}
                            render={({ field }) => <TextField  error={!!errors.rlo} type="number" size="small" id="rlo"  variant="outlined" {...field} />}
                        />               
                        <LightTooltip title={tooltips.rlo} placement="right" arrow>
                            <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                        </LightTooltip> 
                    </Box>

                    {errors.rlo && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Longitude need to be between -180 and 180</Alert>}

                </Box>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Height AGL</CustomTypography></Box>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name="rxh"
                            control={control}
                            rules={{ required: true, min:0.1, max:120000 }}
                            render={({ field }) => <TextField  error={!!errors.rxh} type="number"   size="small" id="rxh"  variant="outlined" {...field} />}
                        /> <CustomTypography>m</CustomTypography>  
                   
                    <LightTooltip title={tooltips.rxh} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
                    </Box>

                    {errors.rxh && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Altitude need to be between 0.1 and 120000</Alert>}

                </Box>
                
            </Box>

            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Receive Gain</CustomTypography></Box>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                        <Controller
                            name="rxg"
                            control={control}
                            rules={{ required: true, min:-10, max:60 }}
                            render={({ field }) => <TextField error={!!errors.rxg} type="number"  size="small" id="rxg"  variant="outlined" {...field} />}
                        /> <CustomTypography>m</CustomTypography>
                    <LightTooltip title={tooltips.rxg} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
                    </Box>
                    {errors.rxg && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Gain need to be between -10 and 60</Alert>}

                </Box>
                
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Measured units</CustomTypography></Box>
                <Box width="220px">
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="out"
                    control={control}
                    // rules={{ required: 'out Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "out",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={1}>Path Loss (dB)</MenuItem>
                            <MenuItem value={2}>Received Power (dBm)</MenuItem>
                            <MenuItem value={3}>Field Strength (dBµV)</MenuItem>
                            <MenuItem value={4}>Signal to Noise Ratio (dB)</MenuItem>
                            <MenuItem value={5}>Bit-Error-Rate (ber)</MenuItem>
                            <MenuItem value={6}>RSRP (dBm)</MenuItem>
                            <MenuItem value={7}>Best site analysis (bsa)</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                </Box>
               
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Sensitivity</CustomTypography></Box>
                <Box sx={{ display:'flex', flexDirection:'column'}}>
                    <Box sx={{ display:'flex', flexDirection:'row'}}>
                <Controller
                    name="rxs"
                    control={control}
                    rules={{ required: true, min:-140, max:200 }}
                    render={({ field }) => <TextField  error={!!errors.rxs} type="number" size="small" id="rxs"  variant="outlined" {...field} />}
                /><CustomTypography>dBm</CustomTypography>
                <LightTooltip title={tooltips.rxs} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
                 </Box>
                    {errors.rxs && <Alert sx={{padding:'0px'}} icon={false} severity="error" >Sensitivity need to be between -140 and 200</Alert>}

                </Box>
                
               
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px"><CustomTypography >Noise floor</CustomTypography></Box>
                <Controller
                    name="noise"
                    control={control}
                    render={({ field }) => <TextField   size="small" id="rxs"  variant="outlined" {...field} />}
                /><CustomTypography>dBm</CustomTypography>
                
               
            </Box>

        </Box>

        </AccordionDetails>
      </Accordion>

            {/* Model section  */}
            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{background:"#818897"}}
        >
            <TuneIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Model</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <Box width="130px">
                Model
                </Box>
                <Box>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="pm"
                    control={control}
                    // rules={{ required: 'pm Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "pm",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={1}>ITM / Longley-Rice (&lt; 20GHz)</MenuItem>
                            <MenuItem value={11}>Egli VHF/UHF (&lt; 1.5GHz)</MenuItem>
                            <MenuItem value={4}>ITU-R P.529 (VHF / UHF)</MenuItem>
                            <MenuItem value={2}>Line of Sight</MenuItem>
                            <MenuItem value={3}>Okumura-Hata (0.15-1.5GHz)</MenuItem>
                            <MenuItem value={6}>COST231-Hata (0.15-2GHz)</MenuItem>
                            <MenuItem value={9}>Ericsson 9999 (0.15-1.9GHz)</MenuItem>
                            <MenuItem value={5}>SUI Microwave (1.9-11GHz)</MenuItem>
                            <MenuItem value={7}>ITU-R P.525 (Optimistic)</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.pm} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
                </Box>
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <CustomTypography  sx={{width:'130px'}}>Reliability</CustomTypography>
            
            <FormControl size="small" variant="outlined">
                    <Controller
                    name="rel"
                    control={control}
                    // rules={{ required: 'rel Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "rel",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={50}>50%</MenuItem>
                            <MenuItem value={60}>60%</MenuItem>
                            <MenuItem value={70}>70%</MenuItem>
                            <MenuItem value={80}>80%</MenuItem>
                            <MenuItem value={90}>90%</MenuItem>
                            <MenuItem value={95}>95%</MenuItem>
                            <MenuItem value={98}>98%</MenuItem>
                            <MenuItem value={99}>99%</MenuItem>
                          
                        
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.rel} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Context</CustomTypography>
                
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="pe"
                    control={control}
                    // rules={{ required: 'pe Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "pe",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                          
                            <MenuItem value={1}>Conservative / Urban</MenuItem>
                            <MenuItem value={2}>Average / Mixed</MenuItem>
                            <MenuItem value={3}>Optimistic / Unobstructed</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.pe} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>

            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography  sx={{width:'130px'}}>Diffraction</CustomTypography>
                
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="ked"
                    control={control}
                    // rules={{ required: 'ked Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "ked",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={0}>Off (LOS)</MenuItem>
                            <MenuItem value={1}>Knife edge</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.ked} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>
            

        </Box>

        </AccordionDetails>
      </Accordion>

      {/* Environment section  */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{background:"#818897"}}
        >
            <NaturePeopleIcon/>
          <Typography sx={{paddingLeft:"5px"}} fontWeight="bold">Environment</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box sx={{display:'flex', flexDirection:'column'}} width='500px'>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Profile</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="clt"
                    control={control}
                    // rules={{ required: 'clt Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "clt",
                            (event?.target as HTMLInputElement).value
                            );
                        }}
                        >
                            <MenuItem value="Minimal.clt">Minimal.clt</MenuItem>
                            <MenuItem value="Desert.clt">Desert.clt</MenuItem>
                            <MenuItem value="Temperate.clt">Temperate.clt</MenuItem>
                            <MenuItem value="Tropical.clt">Tropical.clt</MenuItem>
                            <MenuItem value="Urban.clt">Urban.clt</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.clt} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>
            
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
            <CustomTypography sx={{width:'130px'}}>Elevation</CustomTypography>
            <FormControl size="small" variant="outlined">
                    <Controller
                    name="elevation"
                    control={control}
                    // rules={{ required: 'elevation Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "elevation",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={1}>Surface / DSM</MenuItem>
                            <MenuItem selected value={2}>Terrain / DTM</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.elevation} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Land cover</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="landcover"
                    control={control}
                    // rules={{ required: 'landcover Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "landcover",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={0}>OFF</MenuItem>
                            <MenuItem selected value={1}>ON</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.landcover} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>

            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>Buildings</CustomTypography>
                <FormControl size="small" variant="outlined">
                    <Controller
                    name="buildings"
                    control={control}
                    // rules={{ required: 'buildings Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "buildings",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={0}>OFF</MenuItem>
                            <MenuItem selected value={1}>ON</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.buildings} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>

            </Box>
            <Box sx={{padding:'5px', display:'flex', flexDirection:'row'}}>
                <CustomTypography sx={{width:'130px'}}>My obstacles</CustomTypography>
                
                 <FormControl size="small" variant="outlined">
                    <Controller
                    name="obstacles"
                    control={control}
                    rules={{ required: 'obstacles Required' }}
                    render={({ field: { onChange, value} }) => (
                        <Select 
                        value={value}
                        onChange={(event) => {
                            setValue(
                            "obstacles",
                            parseInt((event?.target as HTMLInputElement).value)
                            );
                        }}
                        >
                            <MenuItem value={0}>OFF</MenuItem>
                            <MenuItem selected value={1}>ON</MenuItem>
                        </Select>
                    )} 
                    />
                </FormControl>
                <LightTooltip title={tooltips.obstacles} placement="right" arrow>
                        <HelpOutlineIcon sx={{color: '#6d7c8c', fontSize:'22px', cursor:'pointer', paddingLeft:"10px", marginTop:"8px"}}/>
                    </LightTooltip>
            </Box>
            

        </Box>

        </AccordionDetails>
      </Accordion>
    </Box>
     <Box sx={{padding:"5px"}} display="flex" flexDirection="row">
        <Button type="submit" size="small" sx={{
                backgroundColor: '#6A7485', 
                color: 'white', 
                width:'140px',
                textTransform: 'capitalize !important',
                ":hover": {
                    bgcolor: "#818897"
                }}}>
                <Box display="flex" flexDirection="row">
                {loading &&
                 <Box sx={{paddingRight:"20px"}}>
                    <CircularProgress sx={{color:'white'}} size={18}/></Box>    
                }
                 <Box>Submit</Box>
                 </Box>
                
        </Button>  
        
        <RFFormTransmittersToggle checked={showTransmitters} handleChange={toggleTransmitters}/>  

        {showTransmitters &&
        <label htmlFor="upload-file" >
            <input
                style={{ display: 'none' }}
                id="upload-file"
                type="file"
                onChange={(event)=>onFileChange(event)}
                accept=".csv"
            />
            <Button size="small" sx={{
                            backgroundColor: '#6A7485', 
                            color: 'white', 
                            width:'160px',
                            marginLeft:'10px',
                            textTransform: 'capitalize !important',
                            ":hover": {
                                bgcolor: "#818897"
                            }}} component="span">
                                <Box display="flex" flexDirection="row">
                {transmittersloading &&
                    <Box sx={{paddingleft:"20px"}}>
                        <CircularProgress sx={{color:'white'}} size={18}/>processing...</Box>    
                }
                {!transmittersloading &&
                 <Box>Upload Transmitters</Box>
                }
                 </Box>
                
            </Button>
        </label>
        }
       
      </Box>
    </form>
  )
}

export default RFForm
