//
import { Alert, Snackbar } from '@mui/material';
import React, { createContext, useContext } from 'react';

const NotificationContext = createContext<INotificationState | undefined>(undefined);

interface INotificationState {
    showAlert: (alertType: AlertType, message: string, duration?: number) => void
}

interface INotificationProvder {
    children: JSX.Element
}

export enum AlertType {
    Success = 'success',
    Error = 'error',
    Info = 'info'
}

const NotificationProvider = ({ children }: INotificationProvder) => {
    const [ snackOpen, setSnackOpen ] = React.useState<boolean>(false);
    const [ alertType, setAlertType ] = React.useState<AlertType>(AlertType.Info);
    const [ alertMessage, setAlertMessage ] = React.useState('');
    const [ duration, setDuration ] = React.useState(6000);

    const showAlert = (alertType: AlertType, message: string, duration: number = 6000) => {
        setSnackOpen(true);
        setAlertMessage(message);
        setAlertType(alertType);
        setDuration(duration);
    }

    return (
        <NotificationContext.Provider
            value={{
                showAlert
            }}
        >
            {children}
            <Snackbar anchorOrigin={{ 
                vertical: 'bottom',
                horizontal: 'center',}} open={snackOpen} autoHideDuration={duration} onClose={() => setSnackOpen(false)}>
                <Alert onClose={() => setSnackOpen(false)} severity={alertType} >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    )
}

const useNotification = () => {
    const context = useContext(NotificationContext);
    if(context === undefined) {
        throw new Error("useNotification can only used inside NotificationProvider")
    }

    return context;
}

export {
    NotificationProvider,
    useNotification
};
