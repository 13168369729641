import { Box } from '@mui/material';
import React from 'react';

enum LegendTypes {
    RAINBOW = 'RAINBOW'
}

const RFLegend = ({ legendType = LegendTypes.RAINBOW }: { legendType: LegendTypes}) => {
    return (
        <Box sx={{ position: 'fixed', left: 10, top: '15vh' }}>
            <img 
                style={{ borderRadius: 10 }}
                src={`https://api.cloudrf.com/API/colours/new/${legendType}.dBm.key.png`}
            />
        </Box>
    )
}

export default React.memo(RFLegend);