import { Box, Stack, Switch, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SwitchProps } from '@mui/material/Switch';
import React, { useState } from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';


type ToggleButtonProps = {
    checked : boolean,
    handleChange : (event : React.ChangeEvent<HTMLInputElement>) => void
}

export const ToggleButton = (props:ToggleButtonProps) => {    

    const CustomSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
      ))(({ theme }) => ({
        width: 50,
        height: 22,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: '#29323C',
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#white',
            border: '6px solid #fff',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 18,
          height: 18,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: '#29323C',
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      }));

    const toolTipText = <Box>
        <Box sx={{fontWeight:'bold', padding:'6px'}}>Advanced Mode</Box>
        <Box sx={{paddingBottom:'6px', paddingLeft:"6px"}}>
          In advanced mode we can upload geojson files and see it in the map
        </Box>
        <Box sx={{fontWeight:'bold', padding:'6px'}}>Simple Mode</Box>
        <Box sx={{paddingBottom:'6px', paddingLeft:"6px"}}>
          In simple mode we can search for locations and see it in map
        </Box>
      </Box>
  
    return (
      <div style={{position: 'absolute', bottom:90, right: 20}}>
        <Stack direction="row" spacing={1} alignItems="center">
        <Typography sx={{color:"gray"}}>{props.checked ? 'Advanced' : 'Simple' } </Typography>
        
        <CustomSwitch 
            checked={props.checked} 
            onChange={props.handleChange} 
            inputProps={{ 'aria-label': 'ant design' }} 
        />
         <Tooltip title={toolTipText} placement="top-end" arrow>
         <HelpOutlineIcon sx={{color: '#29323C', cursor:'pointer'}}/>
          </Tooltip>
        
      </Stack>
      </div>)
  }