import React, { createContext, useContext } from 'react';

const MapContext = createContext<any | undefined>(undefined);

interface IRFMap {
  image_url: string,
  bounds: number[]
}

const MapProvider = ({ children }: any) => {
    const [ rfMapData, setRFMapData ] = React.useState<IRFMap | null>(null);
    const [ transmitterCoordinates, setTransmitterCoordinates ] = React.useState([]);
    
    return (
      <MapContext.Provider
        value={{
          rfMapData,
          setRFMapData,
          transmitterCoordinates,
          setTransmitterCoordinates
        }}
      >
        {children}
      </MapContext.Provider>
    );
};
  
const useMap = () => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error("useMap can only used inside MapProvider");
  }

  return context;
};
  
export { MapProvider, useMap };

