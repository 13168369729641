import ListAltIcon from '@mui/icons-material/ListAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Typography } from '@mui/material';


const CustomLegend = (props:any) => {
    const {legendsData} = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <Tooltip title="Legend" placement="left" arrow>
                <IconButton  style={{ width:"31px", height:"31px", backgroundColor: '#29323C', color: '#A0A7B4' ,borderRadius: 0 }} onClick={handleClick} aria-describedby={id}>
                    <ListAltIcon/>
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}         

            >
                <Box sx={{background:'#29323C', color:'white', padding:'5px'}}>
                <Typography sx={{paddingLeft:'2px', paddingBottom:'4px', fontSize:"14px"}}>Legend</Typography>
                    {legendsData.map((data:any)=>{                         
                    return(
                    <>
                    <Typography sx={{paddingLeft:'5px', fontSize:"13px"}}>{data.layer_id}</Typography>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: '#29323C' }}>
                        {data.layer_colour &&
                            <ListItem sx={{paddingTop:"2px"}} alignItems="flex-start">
                                <Box borderRadius='4px' sx={{background:`${data.layer_colour}`, width:"12px", height:"12px", marginTop:'3px'}}></Box>
                            </ListItem>
                        }
                        {data.min_to_q25_value &&
                            <ListItem sx={{paddingTop:"2px"}} alignItems="flex-start">
                                <Box borderRadius='4px' sx={{background:`${data.min_to_q25_colour}`, width:"12px", height:"12px", marginTop:'3px'}}></Box>
                                <Typography sx={{paddingLeft:'5px', fontSize:"12px"}}>{data.min_to_q25_value}</Typography>
                            </ListItem>
                        }
                        {data.q25_to_q50_value &&
                            <ListItem sx={{paddingTop:"2px"}} alignItems="flex-start">
                                <Box borderRadius='4px' sx={{background:`${data.q25_to_q50_colour}`, width:"12px", height:"12px", marginTop:'3px'}}></Box>
                                <Typography sx={{paddingLeft:'5px', fontSize:"12px"}}>{data.q25_to_q50_value}</Typography>
                            </ListItem>
                        }
                        {data.q50_to_q75_value &&
                            <ListItem sx={{paddingTop:"2px"}} alignItems="flex-start">
                                <Box borderRadius='4px' sx={{background:`${data.q50_to_q75_colour}`, width:"12px", height:"12px", marginTop:'3px'}}></Box>
                                <Typography sx={{paddingLeft:'5px', fontSize:"12px"}}>{data.q50_to_q75_value}</Typography>
                            </ListItem>
                        }
                        {data.q75_to_max_value &&
                            <ListItem sx={{paddingTop:"2px"}} alignItems="flex-start">
                                <Box borderRadius='4px' sx={{background:`${data.q75_to_max_colour}`, width:"12px", height:"12px", marginTop:'3px'}}></Box>
                                <Typography sx={{paddingLeft:'5px', fontSize:"12px"}}>{data.q75_to_max_value}</Typography>
                            </ListItem>
                        }
                        
                    </List>
                    </>
                    )
                    })}

                </Box>
            </Popover>
           
        </>
       
    )
}

export default CustomLegend;