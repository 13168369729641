import React, { useEffect } from 'react';
import SampleModal from '../components/SampleModal';
import Button from '@mui/material/Button';


export default function ModalDemo() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleLoad = (id:string) => {
        console.log(id)
        setOpen(false)
    }
    

    const sampleData = [
        { id : "sadfa1", name:"sara", createdBy:"jihn", createdDate:new Date("2002-01-01")},
        { id : "sadf32", name:"kiran", createdBy:"miky", createdDate:new Date("2002-01-02")},
        { id : "sadf23", name:"sam", createdBy:"andrew", createdDate:new Date("2002-01-03")},
        { id : "sadf54", name:"monu", createdBy:"caryn", createdDate:new Date("2002-01-04")},
        { id : "sadf55", name:"chester", createdBy:"luke", createdDate:new Date("2002-01-01")},
        { id : "sadf66", name:"reneesh", createdBy:"siya", createdDate:new Date("2002-01-02")},
        { id : "sadf67", name:"siya", createdBy:"reneesh", createdDate:new Date("2002-01-03")},
        { id : "sadf68", name:"luke", createdBy:"chester", createdDate:new Date("2002-01-04")},
        { id : "sadf69", name:"caryn", createdBy:"monu", createdDate:new Date("2002-01-01")},
        { id : "sad60", name:"andrew", createdBy:"sam", createdDate:new Date("2002-01-02")},
        { id : "sadf43", name:"miky", createdBy:"kiran", createdDate:new Date("2002-01-03")},
        { id : "sadf534", name:"jihn", createdBy:"sara", createdDate:new Date("2002-01-04")}
    ]

    const [filterdData, setFilterdData] = React.useState(sampleData)


    const handleSearch = (event:React.ChangeEvent<HTMLInputElement>) => {
        let searchString = event.target.value
        let newData : any
        if (searchString) {
          newData = sampleData?.filter((data) => {
            return (
              (
                data.id.toLowerCase().indexOf(searchString.toLowerCase()) > -1 ||
                data.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1 || 
                data.createdBy.toLowerCase().indexOf(searchString.toLowerCase()) > -1
              )
            )
          })
        } else {
          newData = sampleData
        }
        setFilterdData(newData) 

      }

    return(
        <div>
             <Button onClick={handleOpen}>Open modal</Button>
            <SampleModal
                open={open}
                handleClose={handleClose}
                sampleData={filterdData}
                handleLoad={handleLoad}
                handleSearch={handleSearch}
            />
        </div>)
}