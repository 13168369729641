import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Paper, Popper } from '@mui/material';
import React from 'react';

interface IPinPopover {
    id: string | undefined,
    isMoving: boolean 
    anchorElement: HTMLButtonElement | null,
    open: boolean,
    children: React.ReactNode,
    handleClose: (e: any) => void ,
    handleRemove: () => void
}

const PinPopover = ({ 
    id,
    isMoving,
    anchorElement,
    open,
    children,
    handleClose,
    handleRemove
}: IPinPopover) => {
    return (
        <Popper
            disablePortal={isMoving ? true: false}
            id={id}
            sx={{ backgroundColor: 'transparent'}}
            anchorEl={anchorElement}
            open={open}
        >
            <Paper sx={{ border: 0, backgroundColor: '#3a414c', margin: 2, padding: 2, paddingTop: 3, display: "block" }}  variant="outlined" >
               {children}
            </Paper>
            <Box display={"flex"} justifyContent={"space-between"}>
                <IconButton size='small' onClick={handleClose} style={{ backgroundColor: '#3a414c', color: '#A0A7B4' }}><CloseIcon /></IconButton>
                <IconButton size='small' onClick={handleRemove} style={{ backgroundColor: '#3a414c', color: '#A0A7B4' }}><DeleteIcon /></IconButton>
            </Box>
            
        </Popper>
    )
}

export default PinPopover;