import { InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';

interface IInputField {
    label: string, 
    min: number,
    max: number, 
    value: number, 
    unit?: string | null
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputField = ({ 
    label = '',
    min = 1000,
    max = 0, 
    value,
    onChange,
    unit = null
}: IInputField) => {
    return (
        <TextField 
            InputProps={{
                endAdornment: unit ? <InputAdornment position="end"><Typography style={{ color: '#A0A7B4' }}>{unit}</Typography></InputAdornment> : null,
                inputProps: { 
                    max, 
                    min 
                },
                style: { color: '#A0A7B4' }
            }}
            InputLabelProps={{
                style: { color: '#A0A7B4' }
            }}
            value={value}
            onChange={(e) => onChange(e.target.value as any)}
            type='number' 
            sx={{ color: '#A0A7B4', marginRight: 2, width: '9vw',  }} 
            size="small" 
            id={label} 
            label={label} 
            variant="outlined" 
        />
    )
}

export default InputField;