import { Box, Button, IconButton, Modal, TextField, Tooltip, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { buttonStyle } from '../../pages/kepler-raster';
import { getUserToken } from '../utility/Map';
import { AlertType, useNotification } from '../../context/Notifications';
import { style } from '../SavedMaps';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UserTable from './UsersTable';
import AddUser from './AddUser'

const URL = process.env?.REACT_APP_URL || 'http://localhost'

const UserManagement = () => {
    const [ isOpen, setIsOpen ] = React.useState(false);
    const [ isUserModal, setIsUserModal ] = React.useState(false)
    const [ allUsers, setAllUsers ] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(false); 
    const [ searchText, setSearchText ] = React.useState('');
    const { showAlert } = useNotification();

    const getUsers = async () => {
        setIsLoading(true)
        try {
            const token = await getUserToken();
            const response = await fetch(`${URL}/users`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}` //TODO: Please can we make this more generic for all calls
                }
            });
            const data = await response.json();
            
            setAllUsers(data?.users?.users)
        } catch(error)  {
            showAlert(AlertType.Error, "Error loading users")
        } finally {
            setIsLoading(false)
        }
    }

    const createUser = async (username: string, role: string) => {
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const response = await fetch(`${URL}/user`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username, 
                    role
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response?.status}`);
            }

            showAlert(AlertType.Success, "Sucessfully added user");
            setIsUserModal(false);
            await getUsers();
        } catch(error)  {
            showAlert(AlertType.Error, "Error adding user")
        } finally {
            setIsLoading(false)
        }
    }

    const deactivateUser = async (id: string) => {
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const response = await fetch(`${URL}/user/disable`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: id
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response?.status}`);
            }

            showAlert(AlertType.Success, "Sucessfully disabled user");
            setIsUserModal(false);
            await getUsers();
        } catch(error)  {
            showAlert(AlertType.Error, "Error disabling user")
        } finally {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        getUsers();
    }, []);

    const handleClose = () => {
        setIsOpen(false)
    }

    const updateUser = async(username: string, role: string) => {
        try {
            setIsLoading(true)
            const token = await getUserToken();
            const response = await fetch(`${URL}/user`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username, 
                    role
                })
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response?.status}`);
            }

            await getUsers();
            showAlert(AlertType.Success, "Sucessfully added user");
        } catch(error)  {
            showAlert(AlertType.Error, "Error adding user")
        } finally {
            setIsLoading(false)
        }
      }
    
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    
                    <IconButton onClick={() => setIsOpen(false)} style={{
                        backgroundColor: 'white',
                        position: 'fixed',
                        top: -50,
                        right: -50
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', mb: 3 }}>
                        <TextField size='small' placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target?.value)}></TextField>
                        <Modal
                            open={isUserModal}
                            onClose={() => setIsUserModal(false)}
                        >
                           
                            <Box sx={style}>
                                <Box mb={2}>
                                    <Typography variant='subtitle1' align="center">Add a new user</Typography>
                                </Box>
                                <IconButton onClick={() => setIsOpen(false)} style={{
                                    backgroundColor: 'white',
                                    position: 'fixed',
                                    top: -50,
                                    right: -50
                                }}>
                                    <CloseIcon />
                                </IconButton>
                                <AddUser 
                                    isLoading={isLoading}
                                    createUser={createUser}
                                />
                            </Box>
                        </Modal>
                        <Tooltip title="Add New User">
                            <Button onClick={() => {
                                setIsUserModal(true);
                            }} size="small" style={{ backgroundColor: '#A0A7B4', color: 'white' }} ><AddIcon /></Button>
                        </Tooltip>
                       
                    </Box>
                {
                    isLoading && <Box sx={{ display: 'flex', justifyContent: 'center'}}><CircularProgress /> </Box>
                }
                <UserTable 
                    updateUser={updateUser}
                    isLoading={isLoading}
                    sampleData={allUsers} 
                    handleLoad={createUser} 
                    handleClose={() => setIsOpen(false)} 
                    handleDelete={deactivateUser} 
                    searchText={searchText} />
                </Box>
            </Modal>
            <Tooltip title="User Management">
                <IconButton onClick={() => setIsOpen(true)} style={buttonStyle}>
                    <GroupIcon style={{ fontSize: '16px'}} />
                </IconButton>
            </Tooltip>
        </>
    )
}

export default UserManagement;


